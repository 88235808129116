footer {
  color: #fff;
  padding-bottom: 60px;
}
footer .wrap1 {
  background: linear-gradient(62.87deg, #dfeede 8.29%, #76d795 38.65%);
  position: relative;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  width: calc(100% - 20px);
  margin-left: 10px;
  margin-bottom: 10px;
}
footer .wrap1 .bg {
  width: 100%;
  height: 100%;
  position: absolute;
  display: block;
  z-index: 1;
  background-position: left center;
  background-image: url(./../../Assets//Images//footerLand.png);
  background-size: cover;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0.1;
  filter: alpha(opacity = 10);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=" 10 ")";
}

footer .wrap1 .box a > img {
  width: 300px;
  height: 70%;
}

footer .company-info {
  text-align: left;
}

footer .title {
  font-size: 17px;
  line-height: 1.4;
  font-weight: 800;
  margin-bottom: 10px;
  position: relative;
  text-transform: uppercase;
  color: #fff;
}

footer a {
  color: #fff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

footer .company-info i {
  color: #fff;
  width: 20px;
  text-align: center;
}

footer .network_social a.facebook_icon {
  color: #3a5694;
}
.company-info {
  margin-left: 30px;
}

@media (min-width: 992px) {
  footer .network_social a {
    font-size: 30px;
  }
}
footer .network_social a {
  color: #000;
  font-size: 25px;
}
@media (min-width: 1200px) {
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
}

.copyright {
  line-height: 25px;
  font-weight: 700;
}
.text-right {
  text-align: right !important;
}
@media (min-width: 768px) {
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
}

/* Base Styles */

.list-product .item .product-box {
  position: relative;
}
a {
  text-decoration: none !important;
}
.row.pd10 {
  margin-left: -10px;
  margin-right: -10px;
}

.row.pd10 > * {
  padding-left: 10px;
  padding-right: 10px;
}

.content.list-product .product-box .wrap-img-box {
  position: relative;
}

/* Image Box Styles */
.list-product .item .product-box .img-box {
  height: 250px;
  border-radius: 5px;
  overflow: hidden;
}

@media (min-width: 768px) {
  .list-product .item .product-box .img-box {
    height: 300px;
  }
}

@media (min-width: 992px) {
  .list-product .item .product-box .img-box {
    height: 400px;
  }
}

.img-cover,
.object-cover {
  width: auto;
  object-fit: cover;
  min-width: 100%;
  height: 100%;
}

.content.list-product .product-box .wrap-img-box:after {
  content: "";
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  position: absolute;
  border-radius: 9px;
  background: rgba(0, 0, 0, 0.4);
  z-index: 2;
  transform: scale(0.9);
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s linear;
}

/* Info Box Styles */
.list-product .item .product-box .info-box {
  background: #fff;
  transition: all 0.5s ease;
  position: relative;
}

.list-product .item .product-box .info-box .product-name {
  font-size: 14px;
  word-wrap: break-word;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  height: inherit;
  padding-right: 40px;
  position: relative;
}

@media (min-width: 768px) {
  .list-product .item .product-box .info-box .product-name {
    font-size: 15px;
  }
}

@media (min-width: 992px) {
  .list-product .item .product-box .info-box .product-name {
    font-size: 17px;
  }
}

@media (min-width: 1200px) {
  .list-product .item .product-box .info-box .product-name {
    font-size: 20px;
  }
}

.list-product .item .product-box .info-box .product-name a {
  font-weight: 700;
  color: #6d2a6d;
}

.list-product .item .product-box .info-box .cat-url {
  font-size: 14px;
  word-wrap: break-word;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  height: inherit;
  color: #dc7e98;
}

.list-product .item .product-box .info-box .brief {
  word-wrap: break-word;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  height: inherit;
}
.brief {
  color: #000 !important;
}

.list-product .item .product-box .info-box .tour-code {
  font-weight: 600;
}

.list-product .item .product-box .info-box .info span.bgred {
  background: #bd0909;
}

.list-product .item .product-box .info-box .info span {
  line-height: 30px;
  background: #6d2a6d;
  padding: 0 10px;
  border-radius: 5px;
  color: #fff;
  margin-right: 10px;
}

.list-product .item .product-box .info-box .price-more span {
  background: red;
}

.list-product .item .product-box .info-box .price-more span,
.list-product .item .product-box .info-box .price-more a {
  line-height: 40px;
  padding: 0 10px;
  border-radius: 5px;
  color: #fff;
  margin-right: 10px;
}

.list-product .item .product-box .info-box .price-more a {
  background: #dc7e98;
}
.info {
  text-align: left;
}
@media (min-width: 1200px) {
  body {
    font-size: 15px;
  }
}

.containerStart {
  width: 80% !important;
}
.containerStart .list-product .item-wrap {
  border: 1px dotted #a09c9c;
  border-radius: 30px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 10px;
}

.wrap-img-box {
  padding: 0 !important;
}

a {
  text-decoration: none;
}
.hotline-widget .hotline {
  left: 80px !important;
}
.hotline-widget .hotline {
  position: fixed;
  left: 20px;
  bottom: 20px;
  z-index: 50;
  display: inline-block;
  line-height: 1;
  height: 45px;
  color: #fff;
  background: #e2242c;
  background-size: 24px auto;
  padding: 0 10px 0 6px;
  font-weight: 600;
  font-size: 16px;
  border-radius: 32px;
  box-shadow: -2px 2px 5px #404040;
}

.hotline-widget a {
  position: fixed;
  left: 20px;
  bottom: 20px;
  z-index: 50;
  display: inline-block;
  line-height: 40px;
  height: 40px;
  color: #fff;
  background: #b00309;
  background-size: 24px auto;
  padding: 0 15px 0 15px;
  font-weight: bold;
  font-size: 18px;
  border-radius: 18px;
  box-shadow: -2px 2px 5px #404040;
}
.zalo {
  background: white !important;
  position: fixed;
  /* left: 0 !important; */
  bottom: 20px;
  z-index: 50;
  display: inline-block;
  line-height: 40px;
  height: 45px !important;
  width: 45px;
  border-radius: 50% !important;
  color: #fff;
  padding: 0px !important;
}

.zalo img {
  width: 100%;
  height: 100%;
}

.hotline p {
  margin-bottom: 4px;
  margin-top: 5px;
  line-height: 1;
  font-weight: 700;
  font-size: 13px;
}

.hotline-widget .hotline {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: 700;
  z-index: 99;
}
.hotline-widget .hotline i {
  position: relative;
  color: #fff;
  font-size: 16px;
  padding-left: 2px;
  background: rgba(0, 0, 0, 0.3);
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border-radius: 20px;
  margin-right: 5px;
  -moz-animation-duration: 1000ms;
  -moz-animation-name: calllink;
  -moz-animation-iteration-count: infinite;
  -moz-animation-direction: alternate;
  -webkit-animation-duration: 500ms;
  -webkit-animation-name: calllink;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  animation-duration: 1s;
  animation-name: calllink;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.hotline-widget .hotline1 {
  left: 270px !important;
}
.hotline-widget .hotline2 {
  left: 465px !important;
}
.map {
  z-index: 99;
}
.map .map-wrap {
  width: 100% !important;
  height: 150px !important;
  border-radius: 5px;
  -webkit-border-radius: 5px;
}
.map .map-wrap img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  -webkit-border-radius: 5px;
}
.map .map-wrap:hover {
  cursor: pointer;
  transform: scale(1.03);
}
@media (max-width: 768px) {
  .hotline-widget {
    display: none;
  }
}

.zalo {
  animation: play0 1.5s ease infinite;
}

@keyframes calllink {
  0% {
    background-color: rgba(0, 0, 0, 0.1);
  }
  100% {
    background-color: rgba(0, 0, 0, 0.6);
  }
}

@keyframes play0 {
  0% {
    transform: rotate(0deg) scale(1) skew(1deg);
  }
  10% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }
  20% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }
  30% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }
  40% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }
  50% {
    transform: rotate(0deg) scale(1) skew(1deg);
  }
  100% {
    transform: rotate(0deg) scale(1) skew(1deg);
  }
}

.recommended-section12 {
  background-image: url(./../../Assets/Images/DNTN.webp);
  width: 95%;
  margin: 0 auto;
  background-size: auto;
  background-repeat: no-repeat;
}
.recommended-section12 h3 {
  font-size: 1.5rem;
}

.recommended-section12 .container {
  background-position: center;
  background-size: cover;
}

.recommended-section12 .container .bg-shape {
  width: 25%;
  background-color: var(--secondary-bg-color);
  background-size: cover;
  bottom: 0;
  top: 0;
  right: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

@media screen and (max-width: 992px) {
  .recommended-section12 .container .bg-shape {
    width: 50%;
  }
}

@media screen and (max-width: 768px) {
  .recommended-section12 .container .bg-shape {
    width: 100%;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
}

.destinations-page .second-hero-section {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(../../Assets/Images/destinations-hero-bg.jpg);
}

.destinations-page .holiday-plan-section {
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.75)),
    url(../../Assets/Images/destinations-holiday-plan-bg.jpg);
}

.destinations-page .need-inspiration-section {
  background-image: url(../../Assets/Images/destinations-need-inspiration-bg.jpg);
}

.destinations-page .recommended-section .container {
  background-image: url(../../Assets/Images/destinations-recommended-section-bg.jpg);
}

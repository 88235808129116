@import url("https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100..900&display=swap");

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
div {
  margin: 0;
  font-family: "Mulish", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Mulish", sans-serif;
}

h2,
h1 {
  font-family: "Roboto Slab", serif;
  text-transform: uppercase;
}

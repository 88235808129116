.contact-section {
  background-image: url(../../Assets/Images/bgtContact.jpeg);
  width: 100%;
  height: 400px;
  /* position: absolute; */
  display: block;
  z-index: 1;
  background-size: cover;
  background-repeat: no-repeat;
}

.listStar {
  color: #dc7e98;
}

.about-home .about-hotline .hotline img {
  height: 120px;
  margin-right: 10px;
}

.Circle-play {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #fff;
  padding: 30px;
  border-width: 1px;
  border-style: solid;
  border-color: #fff;
  border-radius: 50%;
}

.containerContact {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.containerContact .title {
  font-weight: bold;
  font-size: 35px;
  width: 70%;
  color: #fff;
}
.containerContact p:last-child {
  color: #fff;
  font-size: 20px;
  text-align: center;
}

.listCard {
  width: 100%;
  margin: 0 auto;
  background-color: #fff;
}
.info-box-wrap .item {
  border: 1px dotted #ccc;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  padding: 10px;
  height: 100%;
}

.listCard .info-box {
  height: 150px;
}

.info-box-wrap .hotline img {
  width: 45px;
  height: 45px;
}
.info-box-wrap .hotline .in {
  margin-left: 10px;
  align-items: flex-start;
}
.in a {
  font-size: 18px;
  font-weight: 800;
  color: #6d2a6d;
  line-height: 1.1;
  text-decoration: none;
}
.info-box a.youtube {
  color: #e60023;
}
.info-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

/* Contact Respon */
@media screen and (max-width: 768px) {
  .containerContact .title {
    font-size: 25px;
  }
  .listCard {
    margin: 0 auto !important;
  }
}

.search-form {
    position: absolute;
    width: 75%;
    background-color: var(--primary-color);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media screen and (max-width: 992px) {
    .search-form {
        position: relative;
        width: 90%;
        transform: translate(-55.9%, 0%);
    }
}
a {
  color: #000;
}

img {
  max-width: 100%;
}

.img-cover,
.object-cover {
  width: auto;
  object-fit: cover;
  min-width: 100%;
  height: 100%;
}

.row.pd8 {
  margin-left: -8px;
  margin-right: -8px;
}

.cardItem {
  height: 300px;
}

.recent-holidays .img-div {
  background-size: cover;
  background-position: center;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.recent-holidays .h5,
.recent-holidays .price {
  font-size: 1.5rem;
}

@media screen and (max-width: 576px) {
  .recent-holidays .img-div {
    height: 200px;
  }
}

.home-product .list-product-home .item .img-box {
  flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
  height: 300px;
}

.home-product .list-product-home .item .img-box img {
  border-radius: 10px 10px 0 0;
  -webkit-border-radius: 10px 10px 0 0;
  -moz-border-radius: 10px 10px 0 0;
}

.home-product .list-product-home .item .info-box {
  flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
  background: #f8f8f8;
}

.home-product .list-product-home .item .info-box .product-name {
  word-wrap: break-word;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: limit-lines;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  height: inherit;
  line-height: 1.2;
}

.home-product .list-product-home .item .info-box .product-name a {
  color: #6d2a6d;
  font-weight: 600;
  font-size: 15px;
  line-height: 1.3;
  text-decoration: none;
}

.home-product .list-product-home .item .info-box .brief {
  word-wrap: break-word;
  overflow: hidden;
  color: #000;
  display: -webkit-box;
  text-overflow: limit-lines;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  height: inherit;
}

.home-product .list-product-home .item .info-box .info {
  line-height: 30px;
  padding: 0 10px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  background: var(--text-color);
  color: #fff;
  font-size: 13px;
}

.home-product .list-product-home .item .info-box .info span {
  display: inline-block;
  padding-left: 10px;
  margin-left: 10px;
  position: relative;
}

.home-product .list-product-home .item .info-box .info span:first-child {
  padding-left: 0;
  margin-left: 0;
}

.home-product .list-product-home .item .book {
  flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
  background: var(--detail-gb-color);
  color: #fff;
  border-radius: 0 0 10px 10px;
  -webkit-border-radius: 0 0 10px 10px;
  -moz-border-radius: 0 0 10px 10px;
}

.home-product .list-product-home .item .book .big-price {
  font-weight: 900;
  font-size: 30px;
  line-height: 1.1;
}

.home-product .list-product-home .item .book .price-dvt {
  font-weight: 600;
  font-size: 16px;
}

.home-product .list-product-home .item .book .button {
  font-weight: 600;
  line-height: 30px;
  border: 1px solid #fff;
  color: #fff;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
}

@media (min-width: 768px) {
  .home-product .list-product-home .item .img-box {
    flex: 0 0 36.5%;
    max-width: 36.5%;
    width: 36.5%;
  }

  .home-product .list-product-home .item .info-box {
    flex: 0 0 60%;
    max-width: 60%;
    width: 60%;
    border-radius: 0 0 0 10px;
    -webkit-border-radius: 0 0 0 10px;
    -moz-border-radius: 0 0 0 10px;
  }

  .home-product .list-product-home .item .book {
    flex: 0 0 40%;
    max-width: 40%;
    width: 40%;
    border-radius: 0 0 10px 0;
    -webkit-border-radius: 0 0 10px 0;
    -moz-border-radius: 0 0 10px 0;
  }

  .home-product .list-product-home .item .book .big-price {
    font-size: 40px;
  }

  .home-product .list-product-home .item .book .price-dvt {
    font-size: 17px;
  }

  .home-product .list-product-home .item .book .button {
    line-height: 35px;
  }

  .home-product .list-product-home .item .info-box .product-name a {
    font-size: 16px;
  }
}

@media (min-width: 992px) {
  .home-product .list-product-home .item .img-box {
    flex: 0 0 36.5%;
    max-width: 36.5%;
    width: 36.5%;
  }

  .home-product .list-product-home .item .img-box img {
    border-radius: 10px 0 0 10px;
    -webkit-border-radius: 10px 0 0 10px;
    -moz-border-radius: 10px 0 0 10px;
  }

  .home-product .list-product-home .item .info-box {
    flex: 0 0 36%;
    max-width: 36%;
    width: 36%;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
  }

  .home-product .list-product-home .item .book {
    flex: 0 0 27.5%;
    max-width: 27.5%;
    width: 27.5%;
    border-radius: 0 10px 10px 0;
    -webkit-border-radius: 0 10px 10px 0;
    -moz-border-radius: 0 10px 10px 0;
  }

  .home-product .list-product-home .item .book .big-price {
    font-size: 42px;
  }

  .home-product .list-product-home .item .book .price-dvt {
    font-size: 19px;
  }

  .home-product .list-product-home .item .book .button {
    line-height: 38px;
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
  }

  .home-product .list-product-home .item .info-box .product-name a {
    font-size: 18px;
  }
}

@media (min-width: 1200px) {
  .home-product .list-product-home .item .book .big-price {
    font-size: 45px;
  }

  .home-product .list-product-home .item .book .price-dvt {
    font-size: 20px;
  }

  .home-product .list-product-home .item .book .button {
    line-height: 40px;
  }

  .home-product .list-product-home .item .info-box .product-name a {
    font-size: 20px;
  }
}

.btnViewAll > a {
  text-decoration: none;
  color: #fff;
  font-weight: 500;
}
.btnViewAll:hover {
  background: var(--detail-gb-color);
}

.hotTour .brief {
  color: #000 !important;
}

:root {
  --primary-color: rgb(73 194 113 / 80%);
  --secondary-color: rgb(73 194 113 / 80%);
  --primary-bg-color: rgb(73 194 113 / 80%);
  --secondary-bg-color: rgb(73 194 113 / 80%);

  --detail-gb-color: linear-gradient(62.87deg, #a1e69c 8.29%, #76d795 38.65%);

  --scrolled-bg-color: #76d795;

  --text-color: #76d795;

  /* Border */
  --border-detail-color: 1px solid #76d795;

  /* Background */
  --bgr-header-active: #95d2b3;

  /* Button */
  --btn-primary-color: #55ad9b;
}

.App {
  text-align: center;
  width: 100%;
  overflow: hidden;
}

.App h1 {
  font-size: 4.5rem;
}

.App h2 {
  font-size: 2.5rem;
  background: var(--primary-color);
  -webkit-background-clip: text;
  color: transparent;
}

.App h3 {
  font-size: 2.5rem;
}
/* .App {
  background-color: #007BFF;
} */

.text-green {
  color: var(--primary-color);
}

.img-hover,
.img-hover:hover {
  transition: all 0.5s ease;
}

.img-hover:hover {
  transform: scale(1.03);
}

@media screen and (max-width: 768px) {
  .App h2 {
    font-size: 2.5rem;
  }
  .App .container {
    width: 95% !important;
  }
}
@media screen and (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 95% !important;
  }
}

@media screen and (max-width: 576px) {
  .App h1 {
    font-size: 3rem;
  }

  .App h2 {
    font-size: 2rem;
  }

  .App h3 {
    font-size: 1.75rem;
  }
}

.scrolled {
  z-index: 999;
  background-color: var(--scrolled-bg-color) !important;
  opacity: 0.9 !important;
}

.scrolled a {
  font-weight: bold;
}

.btn_vip {
  width: 150px;
  height: 50px;
  padding: 10px;
  border-radius: 10px;
  background-color: var(--btn-primary-color);
  color: white;
  font-weight: 500;
}

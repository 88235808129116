.header-box .title-box .title {
  font-size: 2rem;
  color: var(--text-color);
}

.header-box .title-box .brief {
  color: #e76ea6;
}

.comment .wrap:after {
  content: "";
  position: absolute;
  display: block;
  width: 70%;
  height: 100%;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  top: 0;
  left: 15%;
  padding: 20px;
  background: #9ae8a8;
  opacity: 0.3;
  filter: alpha(opacity = 30);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=" 30 ")";
}

.comment .owl-carousel {
  display: flex;
  align-items: center;
}

/* .comment .owl-carousel .item-wrap > .item {
  background-color: #d10404;
} */

.comment .item {
  position: relative;
  z-index: 99;
  background: #fff;
  -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 0 20px 0 20px;
  -webkit-border-radius: 0 20px 0 20px;
  -moz-border-radius: 0 20px 0 20px;
}
.comment .item .feed_content {
  word-wrap: break-word;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: limit-lines;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  height: inherit;
}

.comment .item-wrap .item .info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 10px;
}

.comment .item-wrap .item .feed_brief {
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--text-color);
}

.owl-dot-item {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--text-color);
  margin: 0 5px;
}

.doteAcitve {
  background-color: #e76ea6;
}

.comment .item .img-box {
  position: relative;
  border-radius: 6px;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border: 1px solid #d7d5d5;
}
.comment .item .img-box .img {
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  position: relative;
  z-index: 5;
}
.comment .item .img-box .img {
  height: 100%;
  width: 100%;
}
/* .comment .item .img-box:after {
  content: "";
  background: url(../css/images/icon.png) no-repeat;
  width: 25px;
  height: 17px;
  background-size: 25px 17px;
  position: absolute;
  display: block;
  right: 0px;
  top: 2px;
  z-index: 8;
} */
@media (max-width: 768px) {
  .comment .owl-carousel {
    flex-direction: column;
  }
}

.navbar {
  background-color: var(--primary-bg-color);
}

.navbar .navbar-toggler {
  border-color: white;
}

.navbar .navbar-brand img {
  width: 7.5rem;
}
.navbar .btn {
  background-color: var(--btn-primary-color);
  color: white;
}

@media screen and (max-width: 576px) {
  .navbar {
    background-color: var(--secondary-bg-color);
  }
}

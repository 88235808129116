.pather-section .listBrand {
  justify-content: space-evenly;
}
.pather-section .listStar .brand {
  width: 15vw;
  height: 15vw;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
}
.pather-section .listStar .brand img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.partner .partner-list .item a {
  background: #fff;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  overflow: hidden;
  transition: all 0.5s ease-out;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  padding: 10px 0;
  width: 150px;
  height: 150px;
}
.partner .partner-list .item a img {
  max-width: 60%;
  max-height: 60%;
  transition: all 0.5s ease-out;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
}
.partner .partner-list .item.box-f {
  padding: 10px;
}
.title-box .title {
  font-size: 2rem;
  font-weight: bold;
  color: #6d2a6d;
}
.title-box p:last-child {
  font-size: 1.5rem;
  color: #dc7e98;
}

.about-home {
  position: relative;
  margin-top: 150px;
}

.about-home .bg {
  background-image: url(./../../Assets/Images/bgrHXH.jpeg);
  width: 100%;
  height: 100%;
  display: block;
  z-index: 1;
  position: absolute;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  top: 0;
  left: 0;
}
.about-home::after {
  width: 100%;
  height: 100%;
  position: absolute;
  display: block;
  z-index: 1;
  content: "";
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
}
.listCard {
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.about-home .about-content {
  text-align: left;
}
.about-home .container {
  z-index: 99;
}

.about-home .about-content .title {
  color: #dc7e98;
  font-size: 1.2rem;
  font-weight: bold;
}
.about-home .about-content .info {
  font-size: 2rem;
  line-height: 1.4;
  color: #fff;
  font-weight: bold;
}

.about-home .about-content,
.about-home .about-hotline {
  z-index: 99;
}
.about-home .about-hotline .text {
  color: #fff;
}
.about-home .about-hotline .text a {
  text-decoration: none;
  color: #fff;
}
.brief {
  color: #fff;
}

@media screen and (max-width: 768px) {
  .about-home {
    margin-top: 30rem;
  }
  .big-slide {
    height: 65vw !important;
  }
}

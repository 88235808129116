.holidays-page .container .s35_t {
  font-size: 3rem;
  font-weight: normal;
  margin-bottom: 0;
}

.holidays-page .container .s35_m h2 {
  font-weight: 700;
  line-height: 1.2;
  color: #000;
  margin: 0 0 15px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  line-height: 1.2;
  color: #000;
  margin: 0 0 15px;
}
p,
ul,
ol {
  margin: 0 0 1.2rem;
}
